@import 'libs/northstar/src/styles/pxToRem';

.container {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: var(--background-primary);

  display: flex;
  justify-content: space-between;

  .tabs {
    position: relative;

    display: flex;
    gap: --px-to-rem(8);

    .tab {
      padding: --px-to-rem(2);

      &__inner {
        padding: --px-to-rem(10) --px-to-rem(16);
        color: var(--black-2);

        transition: all 0.2s ease;

        & > p {
          font-weight: 400;
        }

        &--active {
          background: var(--background-primary);
          color: var(--black-2);

          & > p {
            font-weight: 700;
          }
        }

        &--disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  &--underline {
    border-bottom: 1px solid var(--cloud-3);

    .tabs {
      gap: --px-to-rem(12);

      .tab {
        padding-bottom: --px-to-rem(10);

        &__inner {
          border-radius: --px-to-rem(6);
          background: var(--background-primary);

          padding: --px-to-rem(4) --px-to-rem(6);
          color: var(--gray-1);

          &--active {
            color: var(--black-2);
          }

          & > p {
            font-weight: 700;
          }

          &:hover {
            background: var(--cloud-1);
          }
        }
      }

      .bubble {
        position: absolute;
        bottom: -1px;
        border-bottom: 2px solid var(--black-2);
        transition: all 0.3s ease 0.1s;
      }
    }
  }

  &--button-primary {
    .tabs {
      border-radius: 4px;
      background: var(--cloud-2);
      gap: --px-to-rem(0);

      .tab {
        &__inner {
          &--active {
            box-shadow: 0px 4px 8px -6px rgba(24, 39, 75, 0.02),
              0px 8px 24px -4px rgba(24, 39, 75, 0.05);
          }
        }
      }
    }
  }

  &--button-secondary {
    .tabs {
      border-radius: 4px;
      border: 1px solid var(--cloud-3);

      .tab {
        position: relative;
        padding: --px-to-rem(0);

        &:not(:last-child)::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 65%;
          width: 1px;
          background-color: var(--cloud-3);
        }
      }
    }
  }

  .info {
    display: flex;
    gap: --px-to-rem(10);
    align-items: center;
    height: max-content;
    padding-block: --px-to-rem(4);

    .action {
      height: max-content;
    }
  }
}
