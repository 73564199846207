@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  position: relative;
  width: 100%;

  .textarea {
    resize: none;

    border-radius: --px-to-rem(8);
    outline: none;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    border: 1px solid var(--cloud-3);
    background: var(--cloud-0);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    color: var(--black-2);
    width: 100%;

    transition: all 0.1s ease-in-out;

    &--padding-small {
      padding: --px-to-rem(6) --px-to-rem(10);
    }

    &--padding-regular {
      padding: --px-to-rem(13) --px-to-rem(15);
    }

    &--with-label {
      padding-top: --px-to-rem(19);
      padding-bottom: --px-to-rem(7);
    }

    // Error needs to have higher specificity
    &--error {
      border: 1px solid var(--red-1) !important;
      background-color: var(--red-3) !important;
      color: var(--red-1) !important;
    }

    &:focus {
      background: var(--background-primary);
      border: 1px solid var(--gray-2);
    }

    &:not(:placeholder-shown) {
      background: var(--background-primary);
    }

    &:not(:focus):hover {
      background: var(--cloud-1);
    }

    &:disabled {
      border: 1px solid var(--cloud-3);
      opacity: 0.5;
      background: var(--cloud-1);
      color: var(--gray-2);
      cursor: not-allowed;
    }

    &[readonly] {
      border: 1px solid var(--cloud-3);
      background: var(--cloud-0);
      color: var(--gray-1);
      font-weight: 400;

      // Disable hover effect
      &:hover {
        background: var(--cloud-0);
      }
    }

    &::placeholder {
      color: transparent;
      visibility: hidden;
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;

    color: var(--black-2);
    padding: --px-to-rem(13) --px-to-rem(16);

    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;

    transition: all 0.1s ease-in-out;

    // Error needs to have higher specificity
    &--error {
      color: var(--red-1) !important;
    }

    &:hover {
      color: var(--gray-1);
    }

    &:disabled {
      color: var(--gray-2);
    }
  }

  .textarea:placeholder-shown ~ .label {
    color: var(--gray-2);

    .placeholder__text {
      display: block;
    }

    .label__text {
      display: none;
    }
  }

  .textarea:disabled ~ .label {
    color: var(--gray-2);
    cursor: not-allowed;
  }

  // Move label up when input is focused or there is a value
  .textarea:focus ~ .label,
  .textarea:not(:placeholder-shown) ~ .label {
    padding: --px-to-rem(7) --px-to-rem(16);

    color: var(--gray-1);

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    &--padding-small {
      padding: --px-to-rem(7) --px-to-rem(11);
    }

    .placeholder__text {
      display: none;
    }

    .label__text {
      display: block;
    }
  }

  .textarea:disabled ~ .right {
    cursor: not-allowed;
  }

  .errorText {
    color: var(--red-1);

    &--padding-small {
      padding: --px-to-rem(2) --px-to-rem(10);
    }

    &--padding-regular {
      padding: --px-to-rem(2) --px-to-rem(15);
    }
  }
}
